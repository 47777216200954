import React from "react"
import { graphql } from "gatsby"
import MailchimpSubscribe from "react-mailchimp-subscribe"

import Layout from "src/components/Layout"
import MetaTitle from "src/components/MetaTitle"
import styled from "styled-components"

import { Text, Box, theme } from "src/style"

const Container = styled(Box).attrs({
  pt: [10, 11],
  fontSize: "base",
})`
  padding-left: 5%;
  padding-right: 5%;
  letter-spacing: 0.015em;

  ${theme.mediaQueries.small} {
    padding-left: 5%;
  }

  ${theme.mediaQueries.medium} {
    padding-left: 10%;
    padding-right: 10%;
  }

  ${theme.mediaQueries.large} {
    padding-left: calc((100vw - 1300px) / 2);
    padding-right: calc((100vw - 1300px) / 2);
  }

  ${theme.mediaQueries.xlarge} {
    padding-left: calc((100vw - 1500px) / 2);
    padding-right: calc((100vw - 1300px) / 2);
  }
`

const Image = styled.img`
  height: auto;
  width: 100%;
  margin-right: 0.5%;
  padding: 1.5em 1em 0 0;

  ${theme.mediaQueries.small} {
    width: 95%;
    max-width: 1400px;
    padding: 0 3em 0 0;
  }
`

const Section = styled(Box).attrs({
  mt: 8,
  mb: 9,
})``

const Header = styled(Text).attrs({
  fontSize: "sm",
  fontFamily: "boldSans",
  my: 6,
})``

const Paragraph = styled(Text).attrs({
  fontSize: "lg",
  fontFamily: "sans",
  my: 2,
  lineHeight: "1.25",
})`
  a {
    &:before {
      content: "";
      background-image: url("https://prismic-io.s3.amazonaws.com/mp-creative%2F123e9990-4177-43fa-ae0c-ec7b22bcb675_arrow.png");
      background-size: auto 100%;
      background-repeat: no-repeat;
      position: relative;
      display: inline-block;
      height: 0.85rem;
      width: 1.2rem;
      top: 0.5px;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`

const ClientBox = styled(Box).attrs({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
})`
  flex-wrap: wrap;
`

const ContactDetails = styled(Text).attrs({
  fontSize: ["h2", "xl"],
  fontFamily: "serif",
  fontWeight: "normal",
  my: 2,
  lineHeight: "1",
})`
  h3 {
    font-weight: normal;
    margin: 0;
    font-size: inherit;
  }

  a {
    &:hover {
      opacity: 0.4;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
`

const url =
  "https://mpcreative.us2.list-manage.com/subscribe/post?u=8a22caf91c607e7f957a0cd3a&amp;id=5f29b78db6"
   
const FormContainer = styled.div`
  background: transparent;
  border-radius: 0;
  display: inline-block;
  margin-top: 1em;
  width: 100%;
`

const Input = styled.input`
  border: 1px solid #000;
  height: 2.5rem;
  width: calc(100% - 4rem);
  color: #000;
  padding: 0 0 0.1em 0.5em;
  overflow: hidden;
  font-size: 1.25rem;
  background: transparent;
  margin-top: -1em;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #000;
  }

  ${theme.mediaQueries.small} {
    max-width: 430px;
  }
`

const Button = styled.button`
  width: 3rem;
  padding: 0;
  margin: 0 0 0 0.6rem;
  font-size: 1.25rem;
  background: transparent;
  border: 0;

  + div {
    position: absolute;
    padding-left: 0.5em;
    margin-top: 0.4em;
    opacity: 0.25;
  }

  &:focus {
    outline: none;
  }

  &:before {
    content: "";
    background-image: url("https://prismic-io.s3.amazonaws.com/mp-creative%2F123e9990-4177-43fa-ae0c-ec7b22bcb675_arrow.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    height: 0.85rem;
    width: 1.2rem;
    top: 0.5px;
  }

  &:hover {
    opacity: 0.4;
    cursor: pointer;
  }
`

// a custom form
const CustomForm = ({ status, message, onValidated }) => {
  let email
  const submit = () => {
    email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      })
  }

  return (
    <FormContainer>
      <Input
        ref={node => (email = node)}
        type="email"
        placeholder="Subscribe to our newsletter"
      />
      <Button onClick={submit}>OK</Button>

      {status === "sending" && <div>sending...</div>}
      {status === "error" && (
        <div dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {status === "success" && (
        <div dangerouslySetInnerHTML={{ __html: message }} />
      )}
    </FormContainer>
  )
}

export default ({
  data: {
    page: { data },
  },
}) => {
  const {
    navigation_color,
    background_color,
    about_paragraph,
    headline,
    client_logos,
    contact_details,
    job,
  } = data

  const themeName = navigation_color === "BLACK" ? "light" : "dark"
  const defaultBg = navigation_color === "BLACK" ? "#fff" : "#000"
  const bgColor = background_color ? background_color : defaultBg

  return (
    <Layout themeName={themeName} bgColor={bgColor}>
      <MetaTitle title="The Agency" />
      <Container>
        <Text fontSize={["h2", "xl"]} fontFamily="serif" mb={6}>
          {headline.text}
        </Text>
        <Section>
          <Header>About us</Header>
          <Paragraph>{about_paragraph.text}</Paragraph>
        </Section>
        <Section>
          <Header>Selected clients</Header>
          <ClientBox>
            {client_logos.map(logo => {
              return <Image src={logo.image.url} />
            })}
          </ClientBox>
        </Section>
        <Section>
          <Header>Contact us</Header>
          <ContactDetails
            dangerouslySetInnerHTML={{ __html: contact_details.html }}
          />
        </Section>
        <Section>
          <Header marginBottom={["1em", "-0.4em"]}>Follow us</Header>
          <Paragraph
            width={["100%", "20%"]}
            style={{ display: "inline-block" }}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/mpcreativenyc"
            >
              Instagram
            </a>
          </Paragraph>
          <Paragraph
            width={["100%", "80%"]}
            style={{ display: "inline-block" }}
          >
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
                />
              )}
            />
          </Paragraph>
        </Section>
        <Section>
          <Header>Job opportunities</Header>
          <Paragraph dangerouslySetInnerHTML={{ __html: job.html }} />
        </Section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    page: prismicContactPage {
      data {
        background_color
        navigation_color

        headline {
          html
          text
        }
        about_paragraph {
          html
          text
        }
        client_logos {
          image: logo_image {
            url
          }
        }
        contact_details {
          text
          html
        }
        job {
          text
          html
        }
      }
    }
  }
`
